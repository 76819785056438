import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { UserSpaces } from "./UserSpaces";
// import { Bookings } from "./Bookings"
import { Bookings } from "./userspaces/Bookings";
import { Availability } from "./Availability";
import { TabButtons } from "../components/TabButtons";
import { GlobalRefContext } from "../store/GlobalRefs";
import { actionTypes } from "../store/storeTypes";
import {
  getDynamoDBConfig,
  listSpace,
  listBookingAnon,
  lookupCustomer,
} from "../api/api";
import { applyOverrides } from "../components/utils";

const list = ["Spaces", "Bookings", "Availability"];

export const User = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { selected, auth, identity, loggedIn, trace } = store;

  if (trace) console.log("User", loggedIn, identity);

  const grefs = useContext(GlobalRefContext);

  useEffect(() => {
    if (loggedIn.status) {
      const asyncFunction = async () => {
        const resConfig = await getDynamoDBConfig(auth, identity.site);
        const defConfig = await getDynamoDBConfig(auth, "default");

        if (resConfig.statusCode === 200 && defConfig.statusCode === 200) {
          const payload = applyOverrides(
            defConfig.body.Item,
            resConfig.body.Item ? resConfig.body.Item : {}
          );
          //save arrays
          dispatch({
            type: actionTypes.SET_CONFIG,
            payload: payload,
          });
        }

        const resSpaces = await listSpace(auth, identity.site);
        if (resSpaces.statusCode === 200) {
          const body = JSON.parse(resSpaces.body);
          dispatch({
            type: actionTypes.SET_SPACES,
            payload: body.spaces,
          });
          const spacePrice = body.spaces.reduce((a, b) => {
            return { ...a, [b.space]: b.cost };
          }, {});

          dispatch({
            type: actionTypes.SET_SPACE_PRICE,
            payload: spacePrice,
          });
        }
        const resCustomers = await lookupCustomer(
          auth,
          identity.site,
          identity.id
        );

        if (resCustomers.statusCode === 200) {
          dispatch({
            type: actionTypes.SET_CUSTOMERS,
            payload: resCustomers.body,
          });
        }
        const resBookings = await listBookingAnon(
          auth,
          identity.site,
          identity.id
        );

        if (resBookings.statusCode === 200) {
          grefs.bookings.current = JSON.parse(resBookings.body).bookings;
          dispatch({
            type: actionTypes.SET_BOOKINGS_LOADED,
            payload: true,
          });
        } else {
          console.log(
            "errorMessage" in resBookings ? resBookings.errorMessage : "Error"
          );
          alert(
            "errorMessage" in resBookings ? resBookings.errorMessage : "Error"
          );
        }
      };
      asyncFunction();
    }
  }, [auth, dispatch, grefs, identity, loggedIn]);

  // const location = useLocation();

  return loggedIn.status && auth ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <TabButtons list={list} />
      <div
        style={{
          width: "100%",
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "auto",
        }}
      >
        {selected === "Spaces" && <UserSpaces />}
        {selected === "Bookings" && <Bookings />}
        {selected === "Availability" && <Availability />}
      </div>
    </div>
  ) : null;
};
