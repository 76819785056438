import { useContext, useRef, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { calculateAspectRatioFit } from "../../components/utils";

const containerStyle = {
  width: "100%",
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  paddingTop: 10,
};

const imageStyle = {
  pointerEvents: "none",
};

export const FullSize = () => {
  const [store] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const { selectedSpaceImage, spaceRow, trace } = store;
  const [largeSize, setLargeSize] = useState(null);
  const containerRef = useRef();

  if (trace) console.log("FullSize");

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const box = containerRef.current.getBoundingClientRect();

      const largeSize = calculateAspectRatioFit(
        grefs.spaceImageCache.current[spaceRow.space][
          selectedSpaceImage[spaceRow.space]
        ].dimensions.width,
        grefs.spaceImageCache.current[spaceRow.space][
          selectedSpaceImage[spaceRow.space]
        ].dimensions.height,
        box.width,
        box.height - 10,
        true
      );
      setLargeSize(largeSize);
    }
  }, [grefs, spaceRow, selectedSpaceImage]);

  return spaceRow.space in selectedSpaceImage &&
    selectedSpaceImage[spaceRow.space] in
      grefs.spaceImageCache.current[spaceRow.space] ? (
    <div ref={containerRef} style={containerStyle}>
      {largeSize && (
        <img
          style={{
            ...imageStyle,
            width: largeSize.width,
            height: largeSize.height,
          }}
          alt="alt"
          src={
            grefs.spaceImageCache.current[spaceRow.space][
              selectedSpaceImage[spaceRow.space]
            ].image
          }
        ></img>
      )}
    </div>
  ) : null;
};
