import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

export const SpacesTable = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { config, spaces, spaceRow, trace } = store;

  if (trace) console.log("SpacesTable");

  const thStyle = { textAlign: "left", paddingLeft: 10 };

  const clickRow = (item) => {
    dispatch({ type: actionTypes.SET_SPACE_ROW, payload: item });
  };

  const rowStyle = { cursor: "pointer" };
  const selectedRowStyle = {
    cursor: "pointer",
    color: "red",
  };

  return spaces ? (
    <div>
      <table>
        <thead>
          <tr>
            <th style={thStyle}>Name</th>
            <th style={thStyle}>Address</th>
            <th style={thStyle}>{config.translations.cost} ph</th>
            <th style={thStyle}>Availability Colour</th>
          </tr>
        </thead>
        <tbody>
          {spaces
            .sort((a, b) => {
              return a.space > b.space ? 1 : b.space > a.space ? -1 : 0;
            })
            .map((item, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => clickRow(item)}
                  style={
                    spaceRow && item.space === spaceRow.space
                      ? selectedRowStyle
                      : rowStyle
                  }
                >
                  <td
                    style={{
                      width: `${defaultWidth.space}px`,
                      paddingLeft: 10,
                    }}
                  >
                    {item.space}
                  </td>
                  <td
                    style={{
                      width: `${defaultWidth.address}px`,
                      paddingLeft: 10,
                    }}
                  >
                    {item.address}
                  </td>
                  <td
                    style={{
                      width: `${defaultWidth.cost}px`,
                      paddingLeft: 10,
                    }}
                  >
                    {item.cost}
                  </td>
                  <td
                    style={{
                      width: `${defaultWidth.colour}px`,
                      paddingLeft: 10,
                      backgroundColor: item.colour,
                    }}
                  ></td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : null;
};
