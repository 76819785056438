import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../store/Store";
import {
  addCognitoUser,
  deleteCandidate,
  editCandidate,
  listCandidate,
} from "../api/api";

const tdStyle = { paddingLeft: 10, paddingRight: 10 };
const thStyle = { paddingLeft: 10, paddingRight: 10, textAlign: "left" };
const buttonStyle = {
  paddingLeft: 10,
  paddingRight: 10,
  border: "1px solid black",
  borderRadius: 8,
  backgroundColor: "brown",
  color: "#ffffff",
  fontSize: 12,
  paddingTop: 4,
  paddingBottom: 4,
  marginRight: 10,
  cursor: "pointer",
  display: "flex",
};

export const Approvals = () => {
  const [store] = useContext(StoreContext);
  const { auth, identity, trace } = store;
  const [candidates, setCandidates] = useState();
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  if (trace) console.log("Approvals");

  useEffect(() => {
    const asyncfunction = async () => {
      const ret = await listCandidate(auth, identity.site);
      if (ret.statusCode === 200) {
        const body = JSON.parse(ret.body);
        setCandidates(
          body.candidates.filter((item) => {
            return "approved" in item && item.approved ? false : true;
          })
        );
      }
    };
    asyncfunction();
  }, [auth, identity]);

  const onApprove = (item) => {
    if (!approving && !rejecting) {
      setApproving(true);
      const asyncfunction = async () => {
        const ret = await addCognitoUser(auth, { email: item.id });
        if (ret.statusCode === 201) {
          const ret = await editCandidate(auth, { ...item, approved: true });
          if (ret.statusCode === 204) {
            //remove from candidates
            const filteredCandidates = candidates.filter((cand) => {
              return cand.id !== item.id;
            });
            setCandidates(filteredCandidates);
          }
        } else {
          alert(ret.body);
        }
        setApproving(false);
      };
      asyncfunction();
    }
  };
  const onReject = (item) => {
    if (!approving && !rejecting) {
      setRejecting(true);
      const asyncfunction = async () => {
        const resDeleteCandidate = await deleteCandidate(
          auth,
          item.site,
          item.id
        );
        if (resDeleteCandidate.statusCode === 204) {
          //remove from candidates
          const filteredCandidates = candidates.filter((cand) => {
            return cand.id !== item.id;
          });
          setCandidates(filteredCandidates);
        } else {
          alert(resDeleteCandidate.body);
        }
        setRejecting(false);
      };
      asyncfunction();
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        boxSizing: "border-box",
        padding: 40,
      }}
    >
      <div>
        <h1>Approvals</h1>
        {candidates ? (
          <table>
            <thead>
              <tr>
                <th style={thStyle}>eMail</th>
                <th style={thStyle}>First Name</th>
                <th style={thStyle}>Surname</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {candidates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td style={tdStyle}>{item.id}</td>
                    <td style={tdStyle}>{item.firstName}</td>
                    <td style={tdStyle}>{item.surname}</td>
                    <td>
                      <div
                        style={
                          approving
                            ? {
                                ...buttonStyle,
                                backgroundColor: "grey",
                                cursor: "default",
                              }
                            : buttonStyle
                        }
                        onClick={() => onApprove(item)}
                      >
                        Approve
                      </div>
                    </td>
                    <td>
                      <div
                        style={
                          rejecting
                            ? {
                                ...buttonStyle,
                                backgroundColor: "grey",
                                cursor: "default",
                              }
                            : buttonStyle
                        }
                        onClick={() => onReject(item)}
                      >
                        Reject
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};
