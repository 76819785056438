import { useContext, useEffect } from "react";
import { ImageDisplay } from "./ImageDisplay";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { Loader } from "../../components/Loader";
import { getSpaceImage } from "../../api/api";
import { actionTypes } from "../../store/storeTypes";

import { SingleFileUploader } from "../../components/upload/SingleFileUploader";

const containerStyle = {
  display: "flex",
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "auto",
  flexDirection: "column",
  boxSizing: "border-box",
};

export const Images = () => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const {
    auth,
    config,
    deleteImageHappened,
    spaceImageCacheLoaded,
    spaceRow,
    selectedSpaceImage,
    trace,
  } = store;

  if (trace) console.log("Images");

  useEffect(() => {
    if (spaceRow && spaceRow.space in grefs.spaceImageCache.current) {
      //   set default image
      if (
        Object.keys(spaceRow.images).length > 0 &&
        !(spaceRow.space in selectedSpaceImage)
      ) {
        const first = Object.entries(spaceRow.images)
          .filter((fitem) => {
            return +fitem[1].index === 0;
          })
          .map((item) => item[0]);
        if (first.length > 0) {
          dispatch({
            type: actionTypes.SET_SELECTED_SPACE_IMAGE,
            payload: {
              ...selectedSpaceImage,
              [spaceRow.space]: first[0],
            },
          });
        }
      }
      dispatch({
        type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
        payload: true,
      });
    } else {
      dispatch({
        type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
        payload: false,
      });
      //get Images for space
      if (
        spaceRow &&
        spaceRow.images &&
        Object.keys(spaceRow.images).length > 0
      ) {
        const newSpaceImages = {};
        Object.entries(spaceRow.images).forEach((item, i) => {
          const imagefolder = item[1].folder;
          const dimensions = item[1].dimensions;
          const asyncfunction = async () => {
            const filetype = item[1].filetype.split("/")[1];
            const result = await getSpaceImage(auth, imagefolder, filetype);
            newSpaceImages[item[0]] = {
              image: result,
              dimensions: dimensions,
            };

            if (
              Object.keys(newSpaceImages).length ===
              Object.keys(spaceRow.images).length
            ) {
              grefs.spaceImageCache.current = {
                ...grefs.spaceImageCache.current,
                [spaceRow.space]: newSpaceImages,
              };

              const first = Object.entries(spaceRow.images)
                .filter((fitem) => {
                  return +fitem[1].index === 0;
                })
                .map((item) => item[0]);

              if (first.length > 0) {
                dispatch({
                  type: actionTypes.SET_SELECTED_SPACE_IMAGE,
                  payload: {
                    ...selectedSpaceImage,
                    [spaceRow.space]: first[0],
                  },
                });
              }
            }
            dispatch({
              type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
              payload: true,
            });
          };

          asyncfunction();
        });
      }
    }
  }, [auth, dispatch, grefs, spaceRow, selectedSpaceImage]);

  const isLoading = () => {
    if (
      !spaceRow.images ||
      Object.keys(spaceRow.images).length === 0 ||
      deleteImageHappened[spaceRow.space]
    ) {
      return false;
    }
    if (
      spaceImageCacheLoaded &&
      spaceRow.space in grefs.spaceImageCache.current &&
      Object.keys(grefs.spaceImageCache.current[spaceRow.space]).length > 0
    ) {
      return false;
    }
    return true;
  };

  return spaceRow ? (
    <div style={containerStyle}>
      <SingleFileUploader />
      {isLoading() ? <Loader width={100} /> : <ImageDisplay />}
    </div>
  ) : (
    <div style={{ marginTop: 20 }}>{config.translations.firstSelectaSpace}</div>
  );
};
