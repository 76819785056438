import React, { useContext, useState } from "react";
import { StoreContext } from "../store/Store";
import { EditRow } from "./spaces/EditRow";
import { SpacesTable } from "./spaces/SpacesTable";
import { Images } from "./spaces/Images";
import { ColourPalette } from "./spaces/ColourPalette";
import { Loader } from "../components/Loader";

export const Spaces = () => {
  const [store] = useContext(StoreContext);
  const { spaces, showPalette, showSpaceImages, trace } = store;

  if (trace) console.log("Spaces");

  const [defaultWidth] = useState({
    address: 500,
    space: 400,
    colour: 60,
    cost: 100,
  });

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingBottom: 20,
  };

  return spaces ? (
    <div style={parentStyle}>
      <div
        style={{
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <EditRow defaultWidth={defaultWidth} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          paddingLeft: 20,
          paddingRight: 20,
          boxSizing: "border-box",
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "auto",
        }}
      >
        {showSpaceImages ? (
          <Images />
        ) : showPalette ? (
          <ColourPalette />
        ) : (
          <SpacesTable defaultWidth={defaultWidth} />
        )}
      </div>
    </div>
  ) : (
    <div style={parentStyle}>
      <Loader width={100} />
    </div>
  );
};
