import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";

const containerStyle = { padding: 10 };
const captionStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  fontWeight: 600,
};
export const Caption = ({ spaceRow }) => {
  const [store] = useContext(StoreContext);
  const { selectedSpaceImage, trace } = store;
  const [caption, setCaption] = useState("");

  if (trace) console.log("Caption");

  useEffect(() => {
    if (
      spaceRow.space in selectedSpaceImage &&
      selectedSpaceImage[spaceRow.space] in spaceRow.images
    ) {
      setCaption(spaceRow.images[selectedSpaceImage[spaceRow.space]].caption);
    } else {
      setCaption("");
    }
  }, [spaceRow, selectedSpaceImage]);

  return (
    <div style={containerStyle}>
      <div style={captionStyle}>{caption}</div>
    </div>
  );
};
