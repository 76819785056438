import { actionTypes } from "./storeTypes";

export const init_global = {
  auth: undefined,
  bookingsLoaded: false,
  candidate: {},
  candidateConfirmed: false,
  checkoutKeyedBookings: null,
  config: null,
  credentials: null,
  customers: null,
  customerRow: null,
  deleteImageHappened: {},
  forgotPassword: { status: false, user: "" },
  identity: null,
  loggedIn: { status: false, message: "" },
  newBookings: [],
  spaces: null,
  spacePrice: null,
  spaceRow: null,
  spaceImageCacheLoaded: false,
  reload: false,
  requireNewPassword: false,
  selected: "Spaces",
  selectedDateAvailability: null,
  selectedSpace: "",
  selectedSpaceImage: {},
  showAllImages: { active: false, space: "" },
  showCheckout: false,
  showPalette: false,
  showSpaceImages: false,
  status: {},
  trace: false,
};

export function global_reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case actionTypes.SET_BOOKINGS_LOADED: {
      return {
        ...state,
        bookingsLoaded: action.payload,
      };
    }
    case actionTypes.SET_CANDIDATE: {
      return {
        ...state,
        candidate: action.payload,
      };
    }
    case actionTypes.SET_CANDIDATE_CONFIRMED: {
      return {
        ...state,
        candidateConfirmed: action.payload,
      };
    }
    case actionTypes.SET_CHECKOUT_KEYED_BOOKINGS: {
      return {
        ...state,
        checkoutKeyedBookings: action.payload,
      };
    }
    case actionTypes.SET_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case actionTypes.SET_CREDENTIALS: {
      return {
        ...state,
        credentials: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case actionTypes.SET_CUSTOMER_ROW: {
      return {
        ...state,
        customerRow: action.payload,
      };
    }
    case actionTypes.SET_DELETE_IMAGE_HAPPENED: {
      return {
        ...state,
        deleteImageHappened: action.payload,
      };
    }
    case actionTypes.SET_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPassword: action.payload,
      };
    }
    case actionTypes.SET_IDENTITY: {
      return {
        ...state,
        identity: action.payload,
      };
    }
    case actionTypes.SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }
    case actionTypes.SET_NEW_BOOKINGS: {
      return {
        ...state,
        newBookings: action.payload,
      };
    }
    case actionTypes.SET_SPACES: {
      return {
        ...state,
        spaces: action.payload,
      };
    }
    case actionTypes.SET_SPACE_IMAGE_CACHE_LOADED: {
      return {
        ...state,
        spaceImageCacheLoaded: action.payload,
      };
    }
    case actionTypes.SET_SPACE_PRICE: {
      return {
        ...state,
        spacePrice: action.payload,
      };
    }
    case actionTypes.SET_SPACE_ROW: {
      return {
        ...state,
        spaceRow: action.payload,
      };
    }
    case actionTypes.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case actionTypes.SET_REQUIRE_NEW_PASSWORD: {
      return {
        ...state,
        requireNewPassword: action.payload,
      };
    }
    case actionTypes.SET_RELOAD: {
      return {
        ...state,
        reload: action.payload,
      };
    }
    case actionTypes.SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case actionTypes.SET_SHOW_ALL_IMAGES: {
      return {
        ...state,
        showAllImages: action.payload,
      };
    }
    case actionTypes.SET_SHOW_CHECKOUT: {
      return {
        ...state,
        showCheckout: action.payload,
      };
    }
    case actionTypes.SET_SHOW_PALETTE: {
      return {
        ...state,
        showPalette: action.payload,
      };
    }
    case actionTypes.SET_SHOW_SPACE_IMAGES: {
      return {
        ...state,
        showSpaceImages: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_DATE_AVAILABILITY: {
      return {
        ...state,
        selectedDateAvailability: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_SPACE: {
      return {
        ...state,
        selectedSpace: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_SPACE_IMAGE: {
      return {
        ...state,
        selectedSpaceImage: action.payload,
      };
    }
    default:
      return state;
  }
}
