import { useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Admin } from "./pages/Admin";
import { User } from "./pages/User";
import { StoreContext } from "./store/Store";
import { Signup } from "./pages/cognito/Signup";

const App = () => {
  const [store] = useContext(StoreContext);
  const { loggedIn, identity, trace } = store;

  if (trace) console.log("app", loggedIn, identity);

  return (
    <div
      style={{
        fontFamily: "Graphic",
        height: "100vh",
        width: "100vw",
        boxSizing: "border-box",
      }}
    >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/user" element={loggedIn ? <User /> : <Home />} />
        <Route
          exact
          path="/admin"
          element={
            loggedIn && identity && identity.role === "user" ? (
              <Admin />
            ) : (
              <Home />
            )
          }
        />
        <Route exact path="/signup" element={<Signup />} />
      </Routes>
    </div>
  );
};
const RoutedApp = () => (
  <Router
    future={{
      v7_relativeSplatPath: true,
    }}
  >
    <App></App>
  </Router>
);

export default RoutedApp;
