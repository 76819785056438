import React, { useContext, useState } from "react";
import { StoreContext } from "../store/Store";
import { EditRow } from "./customers/EditRow";
import { CustomerTable } from "./customers/CustomerTable";

export const Customers = () => {
  const [store] = useContext(StoreContext);
  const { customers, trace } = store;

  if (trace) console.log("Customers");

  const [defaultWidth] = useState({
    firstName: 300,
    surname: 300,
    space: 400,
  });

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
  };

  return customers ? (
    <div style={parentStyle}>
      <div
        style={{
          width: "100%",
          paddingLeft: 100,
          paddingTop: 40,
          boxSizing: "border-box",
        }}
      >
        <EditRow defaultWidth={defaultWidth} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          paddingLeft: 100,
          boxSizing: "border-box",
        }}
      >
        <CustomerTable defaultWidth={defaultWidth} />
      </div>
    </div>
  ) : null;
};
