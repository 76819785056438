import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { useNavigate } from "react-router-dom";
import Auth from "../services/auth";
import { actionTypes } from "../store/storeTypes";
import { GetCredentials } from "./cognito/GetCredentials";
import { NewPassword } from "./cognito/NewPassword";
import { ForgotPassword } from "./cognito/ForgotPassword";
import {
  addCustomer,
  deleteCandidate,
  lookupCandidateById,
  getSite,
  lookupCustomerById,
} from "../api/api";

import "./buttonLink.css";

const authServ = new Auth();

export const Home = () => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    auth,
    credentials,
    forgotPassword,
    identity,
    loggedIn,
    requireNewPassword,
    trace
  } = store;

  if (trace) console.log("Home", loggedIn, identity);

  const navigate = useNavigate();

  useEffect(() => {
    if (authServ) {
      dispatch({ type: actionTypes.SET_AUTH, payload: authServ });
    }
  }, [dispatch]);

  useEffect(() => {
    if (credentials && auth) {
      const asyncfunction = async () => {
        const res = await auth.login(credentials.user, credentials.password);

        if (res.status === "success") {
          dispatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: { status: true, message: "" },
          });
          //check if admin or customer or candidate

          const retSite = await getSite(credentials.user);
          if (retSite.statusCode === 200 && JSON.parse(retSite.body)) {
            const body = JSON.parse(retSite.body);
            const identity = {
              site: credentials.user,
              id: credentials.user,
              name: body.name,
              role: "user",
            };
            dispatch({ type: actionTypes.SET_IDENTITY, payload: identity });
          } else {
            const resCustomer = await lookupCustomerById(
              auth,
              credentials.user
            );
            if (resCustomer.statusCode === 200) {
              const customerBody = JSON.parse(resCustomer.body)
              if (customerBody.length > 0) {
                if (customerBody.length === 1) {
                  const customer = customerBody[0];
                  const identity = {
                    site: customer.site,
                    id: credentials.user,
                    firstName: customer.firstName,
                    surname: customer.surname,
                    role: "customer",
                  };
                  dispatch({
                    type: actionTypes.SET_IDENTITY,
                    payload: identity,
                  });
                } else {
                  //create form so user can choose the principle
                }
              }
            } else {
              //check if candidate
              const resCandidate = await lookupCandidateById(
                auth,
                credentials.user
              );
              if (resCandidate.statusCode === 200) {
                const candidateBody = JSON.parse(resCandidate.body)
                if (candidateBody.length > 0) {
                  if (candidateBody.length === 1) {
                    const candidate = candidateBody[0];
                    const identity = {
                      site: candidate.site,
                      id: credentials.user,
                      firstName: candidate.firstName,
                      surname: candidate.surname,
                      role: "customer",
                    };
                    dispatch({
                      type: actionTypes.SET_IDENTITY,
                      payload: identity,
                    });
                    const resDeleteCandidate = await deleteCandidate(
                      auth,
                      candidate.site,
                      credentials.user
                    );
                    const resAddCustomer = await addCustomer(auth, identity);
                    if (resAddCustomer.statusCode === 201) {
                      //
                    } else {
                      alert(resAddCustomer.body);
                    }

                    if (resDeleteCandidate.statusCode !== 204) {
                      alert(resDeleteCandidate.body);
                    }
                  } else {
                    //create form so user can choose the principle
                  }
                }
              }
            }
          }
        } else if (res.status === "new-password-required") {
          dispatch({
            type: actionTypes.SET_REQUIRE_NEW_PASSWORD,
            payload: true,
          });
        } else {
          dispatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: { status: false, message: res.errorMessage },
          });
        }
      };
      asyncfunction();
    }
  }, [auth, dispatch, credentials]);

  useEffect(() => {
    if (loggedIn.status && identity) {
      if (identity.role === "customer") {
        navigate("/user");
      }
    }
  }, [loggedIn.status, navigate, identity]);

  const toAdmin = () => {
    navigate("/admin");
  };
  const toUser = () => {
    navigate("/user");
  };

  const liStyle = { marginBottom: 10, fontSize: 14 };
  return loggedIn.status && auth && identity && identity.role === "user" ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <h1 style={{ position: "absolute", top: 50 }}>Booking Spaces</h1>
      <ul>
        <li style={liStyle}>
          <button
            className="button-link"
            onClick={() => {
              toUser();
            }}
          >
            Customer view
          </button>
        </li>
        <li style={liStyle}>
          <button
            className="button-link"
            onClick={() => {
              toAdmin();
            }}
          >
            Administrator features
          </button>
        </li>
      </ul>
    </div>
  ) : (
    <>
      {requireNewPassword ? (
        <NewPassword />
      ) : forgotPassword.status ? (
        <ForgotPassword />
      ) : (
        <GetCredentials />
      )}
    </>
  );
};
