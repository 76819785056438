import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { actionTypes } from "../../store/storeTypes";
import { calculateAspectRatioFit } from "../../components/utils";
import Trashcan from "../../images/svg/Trashcan";
import { deleteSpaceImage } from "../../api/api";

const imageStyle = {
  pointerEvents: "none",
};

const MAXWIDTH = 80;
const MAXHEIGHT = 80;
const IMAGE_MARGIN = 6;

export const Thumbnails = ({ dimensions }) => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const {
    auth,
    config,
    deleteImageHappened,
    identity,
    spaceImageCacheLoaded,
    spaceRow,
    selectedSpaceImage,
    trace
  } = store;

  if (trace) console.log("Thumbnails");

  if (dimensions) {
    const cols = Math.ceil(
      (Object.keys(spaceRow.images).length * (IMAGE_MARGIN * 2 + MAXHEIGHT)) /
        dimensions.height
    );

    const containerStyle = {
      height: `${dimensions.height}px`,
      width: `${cols * (IMAGE_MARGIN + MAXWIDTH)}px`,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      boxSizing: "border-box",
    };

    const deleteImage = (name) => {
      const asyncfunction = async () => {
        const newspaceRow = JSON.parse(JSON.stringify(spaceRow));
        const newspaceRowImages = newspaceRow.images;
        delete newspaceRowImages[name];

        const newImages = grefs.spaceImageCache.current[spaceRow.space];
        delete newImages[name];
        grefs.spaceImageCache.current = {
          ...grefs.spaceImageCache.current,
          [spaceRow.space]: newImages,
        };

        //deleteSpaceImage
        await deleteSpaceImage(
          auth,
          identity.site,
          spaceRow.space,
          name
        );

        const payload = {
          ...newspaceRow,
          images: Object.entries(newspaceRowImages)
            .sort((a, b) => {
              return a[1].index > b[1].index
                ? 1
                : b[1].index > a[1].index
                ? -1
                : 0;
            })
            .reduce((c, d, i) => {
              return { ...c, [d[0]]: { ...d[1], index: i } };
            }, {}),
        }

        dispatch({
          type: actionTypes.SET_SPACE_ROW,
          payload: payload
        });
        dispatch({
          type: actionTypes.SET_DELETE_IMAGE_HAPPENED,
          payload: { ...deleteImageHappened, [spaceRow.space]: true },
        });
      };
      asyncfunction();
    };

    return spaceImageCacheLoaded &&
      spaceRow.space in grefs.spaceImageCache.current ? (
      <div style={containerStyle}>
        {Object.entries(spaceRow.images)
          .sort((a, b) => {
            return a[1].index > b[1].index
              ? 1
              : b[1].index > a[1].index
              ? -1
              : 0;
          })
          .map((item, i) => {

            const smallSize = calculateAspectRatioFit(
              item[1].dimensions.width,
              item[1].dimensions.height,
              MAXWIDTH,
              MAXHEIGHT
            );
            
            return (
              <div
                key={"thumnail_" + i}
                style={{
                  position: "relative",
                  width: smallSize.width,
                  height: smallSize.height,
                  margin: IMAGE_MARGIN,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                    backgroundColor: "#ffffff",
                  }}
                  title={config.translations.deleteThisImage}
                  onClick={() => deleteImage(item[0])}
                >
                  <Trashcan width={16} height={16} />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  title="Select this image"
                  onClick={() =>
                    dispatch({
                      type: actionTypes.SET_SELECTED_SPACE_IMAGE,
                      payload: {
                        ...selectedSpaceImage,
                        [spaceRow.space]: item[0],
                      },
                    })
                  }
                >
                  <img
                    style={{
                      ...imageStyle,
                      width: smallSize.width,
                      height: smallSize.height,
                    }}
                    alt="alt"
                    src={
                      grefs.spaceImageCache.current[spaceRow.space][item[0]]
                        .image
                    }
                  ></img>
                </div>
              </div>
            );
          })}
      </div>
    ) : null;
  } else return null;
};
