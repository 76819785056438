import { useContext, useRef, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";
import { GlobalRefContext } from "../../store/GlobalRefs";
import { actionTypes } from "../../store/storeTypes";
import { Thumbnails } from "./Thumbnails";
import { Loader } from "../../components/Loader";
import { SpaceHeader } from "./SpaceHeader";
import { ImageWithCaption } from "./ImageWithCaption";
import { getSpaceImage } from "../../api/api";

const containerStyle = {
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "auto",
};

const parentStyle = {
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};

export const ImageDisplay = () => {
  const [store, dispatch] = useContext(StoreContext);
  const grefs = useContext(GlobalRefContext);
  const {
    auth,
    selectedSpaceImage,
    spaces,
    spaceImageCacheLoaded,
    showAllImages,
    trace,
  } = store;
  const [dimensions, setDimensions] = useState(null);
  const [spaceRow, setSpaceRow] = useState();
  const containerRef = useRef();

  if (trace) console.log("ImageDisplay");

  useEffect(() => {
    if (spaces && showAllImages.active && showAllImages.space) {
      const spaceRow = spaces.find((item) => {
        return item.space === showAllImages.space;
      });
      setSpaceRow(spaceRow);
    }
  }, [showAllImages, spaces]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setTimeout(() => {
        const box = containerRef.current.getBoundingClientRect();
        setDimensions(box);
      }, 10);
    }
  }, []);

  useEffect(() => {
    if (
      spaceRow &&
      spaceRow.images &&
      Object.keys(spaceRow.images).length > 0
    ) {
      if (
        Object.keys(grefs.spaceImageCache.current[spaceRow.space]).length ===
        Object.keys(spaceRow.images).length
      ) {
        if (spaceRow.space in selectedSpaceImage) {
          //
        } else {
          const first = Object.entries(spaceRow.images)
            .filter((fitem) => {
              return +fitem[1].index === 0;
            })
            .map((item) => item[0]);
          if (first.length > 0) {
            dispatch({
              type: actionTypes.SET_SELECTED_SPACE_IMAGE,
              payload: {
                ...selectedSpaceImage,
                [spaceRow.space]: first[0],
              },
            });
          }
        }
        dispatch({
          type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
          payload: true,
        });
      } else {
        dispatch({
          type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
          payload: false,
        });
        //get Images for space
        Object.entries(spaceRow.images).forEach((item, i) => {
          const imagefolder = item[1].folder;
          const dimensions = item[1].dimensions;
          const asyncfunction = async () => {
            const filetype = item[1].filetype.split("/")[1];
            const result = await getSpaceImage(auth, imagefolder, filetype);
            grefs.spaceImageCache.current[spaceRow.space][item[0]] = {
              image: result,
              dimensions: dimensions,
            };
            if (
              Object.keys(grefs.spaceImageCache.current[spaceRow.space])
                .length === Object.keys(spaceRow.images).length
            ) {
              if (spaceRow.space in selectedSpaceImage) {
                //
              } else {
                const first = Object.entries(spaceRow.images)
                  .filter((fitem) => {
                    return +fitem[1].index === 0;
                  })
                  .map((item) => item[0]);
                if (first.length > 0) {
                  dispatch({
                    type: actionTypes.SET_SELECTED_SPACE_IMAGE,
                    payload: {
                      ...selectedSpaceImage,
                      [spaceRow.space]: first[0],
                    },
                  });
                }
              }

              dispatch({
                type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
                payload: true,
              });
            }
          };
          if (item[0] in grefs.spaceImageCache.current[spaceRow.space]) {
            //do nothing
          } else {
            asyncfunction();
          }
        });
      }
    } else {
      dispatch({
        type: actionTypes.SET_SPACE_IMAGE_CACHE_LOADED,
        payload: false,
      });
    }
  }, [auth, dispatch, grefs, spaceRow, selectedSpaceImage]);

  return (
    <div style={parentStyle}>
      <SpaceHeader spaceRow={spaceRow} />
      <div ref={containerRef} style={containerStyle}>
        <TrueCondition
          dimensions={dimensions}
          spaceRow={spaceRow}
          grefs={grefs}
          spaceImageCacheLoaded={spaceImageCacheLoaded}
        />
      </div>
    </div>
  );
};

const TrueCondition = ({
  dimensions,
  spaceRow,
  grefs,
  spaceImageCacheLoaded,
}) => {
  if (spaceRow) {
    if (spaceImageCacheLoaded) {
      if (
        spaceRow.space in grefs.spaceImageCache.current &&
        spaceImageCacheLoaded
      ) {
        if (
          Object.keys(grefs.spaceImageCache.current[spaceRow.space]).length > 0
        ) {
          return (
            <>
              <Thumbnails dimensions={dimensions} spaceRow={spaceRow} />
              <ImageWithCaption spaceRow={spaceRow} />
            </>
          );
        }
      } else {
        return null;
      }
    } else {
      if (spaceRow.images && Object.keys(spaceRow.images).length > 0) {
        return <LoaderWithContainer dimensions={dimensions} />;
      } else {
        return null;
      }
    }
  } else {
    return <LoaderWithContainer dimensions={dimensions} />;
  }
};

const LoaderWithContainer = ({ dimensions }) => {
  return (
    <div
      style={{
        width: "100%",
        height: dimensions ? `${dimensions.height}px` : "0px",
      }}
    >
      <Loader width={100}></Loader>
    </div>
  );
};
