import { useContext, useEffect, useState } from "react";
import { TextField, Button, DialogActions } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { addSpace, deleteSpace } from "../../api/api";

import { deepCopyStringify } from "../../components/utils";

const containerStyle = {
  width: "100%",
  //   padding: 20,
  //   marginLeft: -20,
};
const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "start",
  gap: 10,
};

const editInputStyle = {
  display: "flex",
  justifyContent: "start",
  paddingTop: 20,
  paddingBottom: 10,
  gap: 10,
};

export const EditRow = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    auth,
    config,
    identity,
    spaceRow,
    spaces,
    showPalette,
    showSpaceImages,
    trace,
  } = store;

  const [clear, setClear] = useState(false);

  if (trace) console.log("EditRow", config);

  useEffect(() => {
    if (!spaceRow && spaces.length > 0 && !clear) {
      dispatch({ type: actionTypes.SET_SPACE_ROW, payload: spaces[0] });
    }
  }, [dispatch, spaceRow, spaces, clear]);

  const onChange = (val, key) => {
    const newRow = { ...spaceRow, [key]: val };
    dispatch({ type: actionTypes.SET_SPACE_ROW, payload: newRow });
  };

  const validateRow = (key, newRow) => {
    if (spaceRow && key in spaceRow && (spaceRow[key] || spaceRow[key] === 0)) {
      newRow[key] = spaceRow[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };
  const onSave = () => {
    const newRow = { site: identity.site };
    if (!validateRow("space", newRow)) return;
    if (!validateRow("address", newRow)) return;
    if (!validateRow("description", newRow)) return;
    if (!validateRow("colour", newRow)) return;
    if (!validateRow("cost", newRow)) return;
    //if (!validateRow("images", newRow)) return;
    newRow.images = spaceRow.images;

    //add to spaces data
    const newSpaces = spaces.filter((item) => !(item.space === newRow.space));
    newSpaces.push(newRow);
    dispatch({
      type: actionTypes.SET_SPACES,
      payload: newSpaces,
    });

    // SET_SPACES
    const strNewRow = deepCopyStringify(newRow);
    const asyncfunction = async () => {
      await addSpace(auth, strNewRow);
    };
    asyncfunction();
  };

  const onClear = () => {
    setClear(true);
    dispatch({ type: actionTypes.SET_SPACE_ROW, payload: null });
  };
  const onDelete = () => {
    if (spaceRow && "space" in spaceRow && spaceRow.space) {
      if (window.confirm(`Delete ${spaceRow.space}`)) {
        const newSpaces = spaces.filter(
          (item) => !(item.space === spaceRow.space)
        );
        dispatch({
          type: actionTypes.SET_SPACES,
          payload: newSpaces,
        });

        dispatch({ type: actionTypes.SET_SPACE_ROW, payload: null });
        const asyncfunction = async () => {
          await deleteSpace(auth, identity.site, spaceRow.space);
        };
        asyncfunction();
      }
    } else {
      alert(`enter a space to delete`);
    }
  };

  const showButton = () => {
    return spaceRow
      ? spaces.find((item) => {
          return item.space === spaceRow.space;
        })
      : false;
  };

  return defaultWidth && config ? (
    <div style={containerStyle}>
      <div style={parentStyle}>
        <DialogActions>
          <Button onClick={() => onClear()}>Clear</Button>
          <Button onClick={() => onSave()}>Save</Button>
          <Button onClick={() => onDelete()}>Delete</Button>
        </DialogActions>
      </div>
      <div style={editInputStyle}>
        <TextField
          style={{ width: `${defaultWidth.space}px`, marginLeft: 10 }}
          label="Name"
          value={spaceRow && spaceRow.space ? spaceRow.space : ""}
          onChange={(e) => onChange(e.target.value, "space")}
        />
        <TextField
          style={{ width: `${defaultWidth.address}px` }}
          label="Address"
          value={spaceRow && spaceRow.address ? spaceRow.address : ""}
          onChange={(e) => onChange(e.target.value, "address")}
        />
        <TextField
          style={{ width: `${defaultWidth.cost}px` }}
          label={config.translations.cost}
          type="number"
          value={spaceRow && spaceRow.cost ? spaceRow.cost : ""}
          onChange={(e) => onChange(e.target.value, "cost")}
        />

        <Button
          style={{
            border: `2px solid ${spaceRow ? spaceRow.colour : "black"}`,
          }}
          onClick={() =>
            dispatch({
              type: actionTypes.SET_SHOW_PALETTE,
              payload: !showPalette,
            })
          }
        >
          Colour
        </Button>

        <Button
          onClick={() =>
            dispatch({
              type: actionTypes.SET_SHOW_SPACE_IMAGES,
              payload: !showSpaceImages,
            })
          }
          disabled={!showButton()}
        >
          {showSpaceImages ? "Spaces" : "Images"}
        </Button>
      </div>
      <TextField
        style={{
          flexGrow: 1,
          flexBasis: "auto",
          flexShrink: 0,
          // paddingLeft: 10,
          marginLeft: 10,
          paddingRight: 10,
          display: "flex",
        }}
        label="Description"
        value={spaceRow && spaceRow.description ? spaceRow.description : ""}
        onChange={(e) => onChange(e.target.value, "description")}
      />
    </div>
  ) : null;
};
