import { useContext, useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { editSpaceImageCaption } from "../../api/api";

const containerStyle = {
  display: "flex",
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 20,
};
export const Caption = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, identity, selectedSpaceImage, spaceRow, trace } = store;
  const [caption, setCaption] = useState("");

  if (trace) console.log("Caption");

  useEffect(() => {
    if (
      selectedSpaceImage &&
      spaceRow.space in selectedSpaceImage &&
      selectedSpaceImage[spaceRow.space] in spaceRow.images
    ) {
      setCaption(spaceRow.images[selectedSpaceImage[spaceRow.space]].caption);
    } else {
      setCaption("");
    }
  }, [spaceRow, selectedSpaceImage]);

  const onSave = () => {
    const asyncfunction = async () => {
      const newImages = {
        ...spaceRow.images,
        [selectedSpaceImage[spaceRow.space]]: {
          ...spaceRow.images[selectedSpaceImage[spaceRow.space]],
          caption: caption,
        },
      };

      await editSpaceImageCaption(auth, {
        payload: {
          site: identity.site,
          space: spaceRow.space,
          imageName: selectedSpaceImage[spaceRow.space],
          caption: caption,
        },
      });
      dispatch({
        type: actionTypes.SET_SPACE_ROW,
        payload: { ...spaceRow, images: newImages },
      });
    };
    asyncfunction();
  };
  return (
    <div style={containerStyle}>
      <TextField
        style={{ width: "100%" }}
        label="Caption"
        value={caption}
        onChange={(e) => setCaption(e.target.value)}
      />
      <Button style={{ width: "60px" }} onClick={() => onSave()}>
        Save
      </Button>
    </div>
  );
};
