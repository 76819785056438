import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

export const CustomerTable = ({ defaultWidth }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { customers } = store;

  const thStyle = { textAlign: "left", paddingLeft: 10 };

  const clickRow = (item) => {
    dispatch({ type: actionTypes.SET_CUSTOMER_ROW, payload: item });
  };

  //   const numberStyle = { textAlign: "right" };
  const rowStyle = { cursor: "pointer" };

  return customers ? (
    <table>
      <thead>
        <tr>
          <th style={thStyle}>Email</th>
          <th style={thStyle}>First Name</th>
          <th style={thStyle}>Surname</th>
        </tr>
      </thead>
      <tbody>
        {customers
          .sort((a, b) => {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          })
          .map((item, i) => {
            return (
              <tr key={i} onClick={() => clickRow(item)} style={rowStyle}>
                <td style={{ width: `${defaultWidth.space}px`, paddingLeft: 10 }}>
                  {item.id}
                </td>
                <td
                  style={{
                    width: `${defaultWidth.firstName}px`,
                    paddingLeft: 10,
                  }}
                >
                  {item.firstName}
                </td>
                <td
                  style={{
                    width: `${defaultWidth.surname}px`,
                    paddingLeft: 10,
                  }}
                >
                  {item.surname}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  ) : null;
};
