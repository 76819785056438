import React, { useContext } from "react";
import { StoreContext } from "../store/Store";
import { Loader } from "../components/Loader";
import { SpaceCard } from "./userspaces/SpaceCard";
import { ImageDisplay } from "./userspaces/ImageDisplay";

export const UserSpaces = () => {
  const [store] = useContext(StoreContext);
  const { showAllImages, spaces, trace } = store;

  if (trace) console.log("UserSpaces");

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingBottom: 20,
  };

  return showAllImages.active ? (
    <ImageDisplay />
  ) : spaces ? (
    <div style={parentStyle}>
      {spaces.map((item, i) => {
        return <SpaceCard key={"space" + i} space={item.space} />;
      })}
    </div>
  ) : (
    <div style={parentStyle}>
      <Loader width={100} />
    </div>
  );
};
