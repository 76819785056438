export const flatten = (data) => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop ? prop + "." + i : "" + i);
      if (l === 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const calculateAspectRatioFit = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
  limit = false
) => {
  if (limit) {
    maxWidth = maxWidth > srcWidth ? srcWidth : maxWidth;
    maxHeight = maxHeight > srcHeight ? srcHeight : maxHeight;
  }
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio,
  };
};

export const deepCopyStringify = (inObject) => {
  let outObject, value, key;
  if (typeof inObject !== "object") {
    return inObject ? inObject.toString() : inObject; // Return the value if inObject is not an object
  }
  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
    value = inObject[key];
    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyStringify(value);
  }
  return outObject;
};

// export const applyOverridesBack = (config, override) =>
//   [config, override].reduce(
//     (r, o) =>
//       Object.entries(o).reduce(
//         (q, [k, v]) => ({
//           ...q,
//           [k]: v && typeof v === "object" ? applyOverrides(q[k] || {}, v) : v,
//         }),
//         r
//       ),
//     {}
//   );

export const applyOverrides = (config, override) =>
  [config, override].reduce(
    (r, o) =>
      Object.entries(o).reduce(
        (q, [k, v]) => ({
          ...q,
          [k]:
            v && typeof v === "object" && !(v instanceof Array)
              ? applyOverrides(q[k] || {}, v)
              : v,
        }),
        r
      ),
    {}
  );

export const deepMergeObjects = (...objs) => {
  /**
   * Get the object type
   * @param  {*}       obj The object
   * @return {String}      The object type
   */
  function getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  }

  // Create a clone of the first item in the objs array
  let clone = structuredClone(objs.shift());

  // Loop through each item
  for (let obj of objs) {
    // Get the object type
    let type = getType(obj);

    // If the current item isn't the same type as the clone, replace it
    if (getType(clone) !== type) {
      clone = structuredClone(obj);
      continue;
    }
  }

  return clone;
};

export const uniqueid = () => {
  // always start with a letter (for DOM friendlyness)
  var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
    var ascicode = Math.floor(Math.random() * 42 + 48);
    if (ascicode < 58 || ascicode > 64) {
      // exclude all chars between : (58) and @ (64)
      idstr += String.fromCharCode(ascicode);
    }
  } while (idstr.length < 32);

  return idstr;
};

export const formatDateforMySQL = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hour = ("0" + date.getHours()).slice(-2);
  const minute = ("0" + date.getMinutes()).slice(-2);
  const second = ("0" + date.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const formatDateforSchedulePosition = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getStartOfWeekDate = (date, weekStartOn) => {
  const diff = date.getDay() - weekStartOn;
  return addDays(date, -(diff < 0 ? diff + 7 : diff));
};
